/**
 * 示例-普通详情页
 * luxinwen
 * 2023-01
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-thead">
        <p>订单编号： E20180524100228057100027</p>
        <p>订单类型： 普通订单</p>
        <p>付款方式： 微信安全支付－代销</p>
        <p>买家： 小可爱</p>
      </div>
      <div class="page-main-content">
        <div class="page-main-content-thead">基本信息</div>
        <div class="page-main-content-tbody">
          <Row>
            <Col span="8">申请人：Aresn</Col>
            <Col span="8">审批人：中小鱼</Col>
            <Col span="8">出差事由：与客户签署合同</Col>
            <Col span="8">合同编号：100000</Col>
            <Col span="8">出差时间：2019-07-28 - 2019-07-30</Col>
            <Col span="8">紧急成都：紧急</Col>
          </Row>
        </div>
      </div>
      <div class="page-main-content">
        <div class="page-main-content-thead">行程信息</div>
        <div class="page-main-content-tbody">
          <Row>
            <Col span="8">出发城市：北京市</Col>
            <Col span="8">出发时间：2019-07-28</Col>
            <Col span="8">返程时间：2019-07-30</Col>
            <Col span="8">交通类型：飞机</Col>
            <Col span="8">航班到达城市：深圳市</Col>
          </Row>
        </div>
      </div>
      <div class="page-main-content">
        <div class="page-main-content-thead">同行物品</div>
        <div class="page-main-content-tbody">
          <sp-table :columns="tableColumns" :data="tableData" disabled-hover>
            <template slot-scope="params" slot="pic">
              <img :src="params.row.pic" class="thumbnail" />
            </template>
          </sp-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tableColumns: [
          {
            title: '图片',
            slot: 'pic'
          },
          {
            title: '名称',
            key: 'name'
          },
          {
            title: '价格',
            key: 'price'
          },
          {
            title: '数量',
            key: 'status'
          }
        ],
        tableData: []
      };
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        this.$axios({
          url: this.$api.test.queryTestList,
          mock: true,
          data: {},
          loading: true
        }).then(data => {
          this.tableData = data.list || [];
        });
      }
    }
  };
</script>